import axios from 'axios';
import { FACEBOOK_API_DATA_LIMIT, FIREBASE_URL_FACEBOOK } from '../constants';

export const getFacebookHiddenInterstApi = async (searchQuery) => {
    let responseData = [];

    const data = await axios.post(FIREBASE_URL_FACEBOOK, {
        searchQuery: searchQuery,
        apiDataLimit: FACEBOOK_API_DATA_LIMIT,
    }, {
        headers: { 'Accept': 'application/json' }
    })
        .then(response => response.data)
        .then(result => result.data)
        .catch((error) => {
            // console.log(error);
        });

    if (data && data.length) {
        // console.log("data:", data)
        responseData = data.map(item => {
            const newItem = {
                // key: item.name,
                name: item.name,
                topic: item.topic,
                audienceSizeLowerBound: item.audience_size_lower_bound,
                audienceSizeUpperBound: item.audience_size_upper_bound,
            }
            return newItem;
        });
    }
    return responseData;
}
