import { Pagination, Row, Tag, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { PAGE_HEAD_TITLE } from '../../constants'
import AppContext from '../../context/AppContext'
import {
  checkQuotaValidation,
  updateRequestUserQuota,
} from '../../services/quotaCheckService'
import {
  shoppingAdsTaskGet,
  shoppingAdsTaskPost,
} from '../../services/shoppingAdsApiService'
import BunnyPlayer from '../BunnyPlayer'
import LocationDropdown, { locationOptions } from '../locationDropdown'
import AmazonListingItem from './amazonListing'
import GoogleShoppingListItem from './googleShopingList'
import './style.css'
// import testData from "./test.json";

const ShoppingAds = (props) => {
  const { setLoadingFullScreen } = useContext(AppContext)

  const authUser = props.user
  const searchType = props.searchType
  // let keywordApiDataLimit = SHOPPING_ADS_KEYWORDS_API_DATA_LIMIT;

  const [inputKey, setInputKey] = useState('Shoes')
  const [searchData, setSearchData] = useState([])
  // const [loading, setLoadingFullScreen] = useState(false);
  const [locationData, setLocationData] = useState(locationOptions[6])
  // const [taskReponse, setTaskReponse] = useState({ taskId: { taskId: "12181327-3166-0209-0000-674a821c2c5b" } });
  const [taskReponse, setTaskReponse] = useState({})

  const [defaultCurrent, setDefaultCurrent] = useState(1)
  const [totalRecords, setTotalRecords] = useState(30) //eslint-disable-line
  const [pageSize, setPageSite] = useState(30)
  const [tempData, setTempData] = useState([])

  useEffect(() => {
    document.title = `${
      searchType.toLowerCase().charAt(0).toUpperCase() +
      searchType.toLowerCase().slice(1).replace('_', ' ')
    } Ads ${PAGE_HEAD_TITLE}`

    // reset to default state. If searchType changes
    setInputKey('')
    setTaskReponse({})
    setSearchData([])
    setTempData([])
    setLocationData(locationOptions[6])
  }, [searchType]) //eslint-disable-line

  useEffect(() => {
    if (taskReponse && taskReponse.taskId && taskReponse.taskId.taskId) {
      findKeywordsTaskData()
    }
  }, [taskReponse]) //eslint-disable-line

  useEffect(() => {
    if (searchType === 'AMAZON_LISTING') {
      onPageChange(defaultCurrent, pageSize)
    }
  }, [searchData]) //eslint-disable-line

  const findKeywordsTaskId = async (type) => {
    // findKeywordsTaskData();
    // return;
    if (!inputKey) {
      notification['error']({ message: 'Please enter search keyword!' })
    } else {
      setLoadingFullScreen(true)

      const checkResponse = await checkQuotaValidation(
        'SHOPPING_ADS',
        authUser,
        {},
      )
      // const checkResponse = { valid: true }

      if (checkResponse.valid === false) {
        setLoadingFullScreen(false)
      } else {
        const dts = {
          keyword: inputKey,
          location_code: locationData.key,
          currency_code: locationData.currencyCode,
          currency_symbol: locationData.currencySymbol,
        }

        const data = await shoppingAdsTaskPost(searchType, dts)
        // const data = {
        //     taskId: "11301416-3166-0209-0000-c75776a27b70",
        //     currencyConversionData: {
        //         currencyCode: locationData.currencyCode,
        //         currencySymbol: locationData.currencySymbol,
        //     }
        // };

        // console.log("data:", data)
        if (data) {
          setTaskReponse(data)
        } else {
          setLoadingFullScreen(false)
          console.log('Something went wrong!!')
          notification['error']({
            message: 'Something went wrong!!',
            placement: 'topRight',
          })
        }

        setLoadingFullScreen(false)
      }
    }
  }

  const findKeywordsTaskData = async () => {
    setLoadingFullScreen(true)

    const dts = {
      id: taskReponse.taskId.taskId,
    }
    // console.log("taskReponse:", taskReponse)
    const data = await shoppingAdsTaskGet(
      searchType,
      dts,
      taskReponse.currencyConversionData,
    )
    // console.log("data:", data)

    if (data && data.status_code === 40102) {
      setLoadingFullScreen(false)
      notification['error']({
        message: data.status_message,
        placement: 'topRight',
      })
    } else if (data && data.status_code === 20000) {
      setSearchData(data)
      if (searchType === 'AMAZON_LISTING') {
        setTotalRecords(data.result.length)
      }

      setLoadingFullScreen(false)
    } else {
      // findKeywordsTaskData();
      setTimeout(() => {
        findKeywordsTaskData()
      }, 5000)
    }
  }

  const onPageChange = (page, pageSize) => {
    setDefaultCurrent(page)
    setPageSite(pageSize)
    const start = (page - 1) * pageSize
    const end = start + pageSize
    let data = []
    if (searchData && searchData.result && searchData.result.length) {
      data = searchData.result.slice(start, end)
    }
    setTempData(data)
    updateRequestUserQuota('SHOPPING_ADS', authUser, {
      numResults: data.length,
    })
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <BunnyPlayer type={searchType} />

        <LocationDropdown
          defaultValue={locationData}
          onChange={(value) => {
            setLocationData(value)
          }}
        />

        <Row className="searchfbInterest">
          <div className="search-input-container">
            <input
              type="text"
              className="text-input"
              placeholder="Enter keyword"
              name="search"
              value={inputKey}
              onChange={(e) => setInputKey(e.target.value)}
            />

            <button
              type="submit"
              className="primary-button search-button"
              onClick={() => findKeywordsTaskId('search')}
            >
              Search
            </button>
          </div>
        </Row>

        <div className="fb_interest_list_page">
          {searchType === 'AMAZON_LISTING' && searchData.categories && (
            <div>
              <h6 className="mt-3 mb-2">Categories</h6>
              {searchData.categories.map((item, index) => {
                return (
                  <Tag
                    key={`categ-${index}`}
                    color="geekblue"
                    style={{ marginBottom: '3px' }}
                  >
                    {item}
                  </Tag>
                )
              })}
            </div>
          )}

          {searchType === 'AMAZON_LISTING' ? (
            <>
              {tempData && tempData.length ? (
                <div className="row">
                  {tempData.map((item, index) => {
                    return (
                      <div key={`url-${index}`} className="col-sm-4 mt-2">
                        <AmazonListingItem item={item} />
                      </div>
                    )
                  })}
                </div>
              ) : null}
            </>
          ) : (
            <>
              {searchData && searchData.result && searchData.result.length ? (
                <div className="row">
                  {searchData.result.map((item, index) => {
                    return (
                      <div key={`url-${index}`} className="col-sm-4 mt-2">
                        <GoogleShoppingListItem item={item} />
                      </div>
                    )
                  })}
                </div>
              ) : null}
            </>
          )}

          {/* {searchData && searchData.result && searchData.result.length ?
                        <div className="pagination-wrapper">
                            <AntButton className="mt-10" onClick={() => findKeywordsTaskId('loadMore')} >Load more results..</AntButton>
                        </div>
                        :
                        null
                    } */}
          {tempData && tempData.length && searchType === 'AMAZON_LISTING' ? (
            <div style={{ marginTop: 20 }}>
              <Pagination
                defaultCurrent={defaultCurrent}
                pageSize={pageSize}
                showSizeChanger={false}
                total={totalRecords}
                onChange={onPageChange}
              />
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ShoppingAds)
