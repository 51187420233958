import React from "react";
// import { Popover } from "antd";
// import { pleaseSubscribe } from "../../utils/Helpers";
import "./index.css";

const SearchInput = props => {

    const {
        inputKey,
        setInputKey,
        findKeywords,
        // accountType,
        placeholder = "Enter keyword",
    } = props;

    return (
        <div className="search-input-container">
            <input type="text" className="text-input" placeholder={placeholder} name="search" value={inputKey} onChange={(e) => setInputKey(e.target.value)} />
            {/* {
            accountType === "FREE" ?
                <Popover content={pleaseSubscribe} className="">
                    <button type="submit" className="primary-button search-button not-allowed-button">Search</button>
                </Popover>
                : */}
            <button type="submit" className="primary-button search-button" onClick={() => findKeywords('search')}>Search</button>
            {/* } */}
        </div>
    )
}

export default SearchInput;
