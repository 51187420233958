import axios from 'axios'
import { firebaseURL } from '../constants'

export const getSpyAdsDataApi = async (filter) => {
  let responseData = []

  const data = await axios
    .post(
      firebaseURL.adspyderAdsApi,
      {
        page: filter.page,
        query: filter.query,
        callType: filter.callType,
        type: filter.type,
        urlCallType: filter.urlCallType,
        ...(filter.country && {
          country: filter.country,
        }),
        sortKey: filter.sortKey,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
  if (data) {
    if (data.documents && data.documents.length) {
      if (filter.type === 'YOUTUBE') {
        responseData = data.documents.map((item) => {
          const newItem = {
            id: item.id,
            title: item.title,
            channel: item.channel,
            content: item.content,
            url: item.url,
            position: item.position,
            views: item.views,
            country: item.country,
            lastSeen: item.lastSeen,
            publishedOn: item.publishedOn,
            videoId: item.videoId,
            likes: item.likes,
            tags: item.tags,
          }
          return newItem
        })
      } else if (filter.type === 'GOOGLE_ECOM') {
        responseData = data.documents.map((item) => {
          const newItem = {
            id: item.id,
            title: item.title,
            campaignid: item.campaignid,
            url: item.url,
            country: item.country,
            domainid: item.domainid,
            price: item.price,
            popularity: item.popularity,
            sitename: item.sitename,
            position: item.position,
            countryCount: item.countryCount,
            image: item.image,
            lastSeen: item.lastSeen,
          }
          return newItem
        })
      } else if (filter.type === 'GOOGLE_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            country: item.country,
            lastSeen: item.lastSeen,
            city: item.city,
            firstSeen: item.firstSeen,
            campaignId: item.campaignId,
            created_at: item.created_at,
            position: item.position,
            id: item.id,
            title: item.title,
            domainId: item.domainId,
            content: item.content,
            url: item.url,
          }
          return newItem
        })
      } else if (filter.type === 'DISPLAY_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            id: item.id,
            title: item.title,
            url: item.url,
            country: item.country,
            appearance: item.appearance,
            agency: item.agency,
            image: item.image,
            lastSeen: item.lastSeen,
          }
          return newItem
        })
      } else if (filter.type === 'FLIPKART_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            url: item.url,
            title: item.title,
            brand: item.brand,
            id: item.id,
            country: item.country,
            price: item.price,
            extraInfo: item.extraInfo,
            city: item.city,
            position: item.position,
            countryCount: item.countryCount,
            image: item.image,
            popularity: item.popularity,
            date: item.date,
          }
          return newItem ? newItem : {}
        })
      } else if (filter.type === 'REDDIT_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            video: item.video,
            content: item.content,
            url: item.url,
            profileUrl: item.profileUrl,
            postUrl: item.postUrl,
            id: item.id,
            country: item.country,
            image: item.image,
            title: item.title,
            city: item.city,
            position: item.position,
            platform: item.platform,
            countryCount: item.countryCount,
            date: item.date,
          }
          return newItem ? newItem : {}
        })
      } else if (filter.type === 'LINKEDIN_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            logo: item.logo,
            country: item.country,
            comments: item.comments,
            image: item.image,
            content: item.content,
            url: item.url,
            ctaButton: item.ctaButton,
            postId: item.postId,
            title: item.title,
            id: item.id,
            city: item.city,
            platform: item.platform,
            followers: item.followers,
            likes: item.likes,
            shares: item.shares,
            lastSeen: item.lastSeen,
            type: item.type,
            ctaContent: item.ctaContent,
            ctaUrl: item.ctaUrl,
            pageId: item.pageId,
            ctaTitle: item.ctaTitle,
            firstSeen: item.firstSeen,
            video: item.video,
          }
          return newItem ? newItem : {}
        })
      } else if (filter.type === 'AMAZON_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            id: item.id,
            country: item.country,
            firstSeen: item.firstSeen,
            lastSeen: item.lastSeen,
            campaignId: item.campaignId,
            title: item.title,
            image: item.image,
            url: item.url,
            price: item.price,
          }
          return newItem ? newItem : {}
        })
      } else if (filter.type === 'FACEBOOK_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            logo: item.logo,
            country: item.country,
            comments: item.comments,
            image: item.image,
            content: item.content,
            url: item.url,
            ctaButton: item.ctaButton,
            postId: item.postId,
            title: item.title,
            id: item.id,
            city: item.city,
            platform: item.platform,
            followers: item.followers,
            likes: item.likes,
            shares: item.shares,
            lastSeen: item.lastSeen,
            type: item.type,
            ctaContent: item.ctaContent,
            ctaUrl: item.ctaUrl,
            pageId: item.pageId,
            ctaTitle: item.ctaTitle,
            firstSeen: item.firstSeen,
            video: item.video,
          }
          return newItem ? newItem : {}
        })
      } else if (filter.type === 'BING_ECOM_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            siteName: item.siteName,
            id: item.id,
            image: item.image,
            lastSeen: item.lastSeen,
            domainId: item.domainId,
            price: item.price,
            country: item.country,
            url: item.url,
            impressionByCountry: item.impressionByCountry,
            title: item.title,
            totalImpression: item.totalImpression,
            position: item.position,
            firstSeen: item.firstSeen,
          }
          return newItem ? newItem : {}
        })
      } else if (filter.type === 'BING_ADS') {
        responseData = data.documents.map((item) => {
          const newItem = {
            url: item.url,
            firstSeen: item.firstSeen,
            id: item.id,
            country: item.country,
            city: item.city,
            position: item.position,
            title: item.title,
            content: item.content,
            campaignId: item.campaignId,
            domainId: item.domainId,
            lastSeen: item.lastSeen,
          }
          return newItem ? newItem : {}
        })
      }
    }
  } else {
    return {}
  }

  return {
    location:
      data && data.location && data.location.countries
        ? data.location.countries
        : [],
    results: responseData,
    success: data.success,
    totalHits: data.totalHits,
  }
}

export const getAdSpyderDetail = async (filter) => {
  const data = await axios
    .post(
      firebaseURL.spyAdsDetailsApi,
      {
        id: filter.id,
      },
      {
        headers: { Accept: 'application/json' },
      },
    )
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })

  return {
    result: data.result,
    success: data.success,
  }
}
