import '@pathofdev/react-tag-input/build/index.css'
import { Col, Spin, notification } from 'antd'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import AppRoutes from './Routes'
import './app.css'
import NavBar from './components/Header/NavBar'
import Sidebar from './components/Sidebar/Sidebar'
import Login from './components/auth/Login'
import Register from './components/auth/Register'
import ResetPassword from './components/auth/ResetPassword'
import {
  FREE_KEYWORD_REQUEST_QUOTA_AI,
  FREE_KEYWORD_RESEARCH_QUOTA,
  FREE_KEYWORD_RESEARCH_QUOTA_BING,
  FREE_KEYWORD_RESEARCH_QUOTA_GOOGLE,
  FREE_KEYWORD_RESEARCH_QUOTA_SPY_ADS,
} from './constants'
import AppContext from './context/AppContext'
import { Auth, Database } from './firebase'
import './styles/antd.css'
import UserContext from './userContext'
import { getUserAccountPlan } from './utils/Helpers'

const Footer = () => {
  return (
    <footer className="footer-content">
      © 2024 All Rights Reserved. HMA TECH & MARKETING SOLUTIONS LLC |  <a href="https://www.marketingwithhma.com" target="_blank" rel="noreferrer">www.marketingwithhma.com</a>
      | Privacy Policy | Terms & Conditions
    </footer>
  );
};

const App = () => {
  const [IsAuth, setIsAuth] = useState(null)
  const [Loading, setLoading] = useState(true)
  const [loadingFullScreen, setLoadingFullScreen] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [unreadCount, setUnreadCount] = useState(0)
  const [unreadMessageArray, setUnreadMessageArray] = useState([])

 

  useEffect(() => {
    Auth.onAuthStateChanged(async (user) => {
      // console.log("onAuthStateChanged")
      if (user) {
        // console.log("AUTH STATUS CHANGED USER:", user)
        const docRef = doc(Database, 'users', user.uid)
        const docSnap = await getDoc(docRef)
        let accountType = 'FREE'
        if (docSnap.exists()) {
          const data = docSnap.data()
          data.uid = user.uid
          user.accountData = data
        } else {
          // console.log("NOT EXISTS")
          // doc.data() will be undefined in this case
          await setDoc(doc(Database, 'users', user.uid), {
            email: user.email,
            name: user.displayName,
            createdDate: Timestamp.fromDate(moment().toDate()),
            subscriptionPlan: {
              price: 0,
              planId: 'FREE',
              planMonthlyYearly: 'YEARLY',
              validityDays: 365,
              quotaAiRequest: FREE_KEYWORD_REQUEST_QUOTA_AI,
              quotaGoogleKwResearch: FREE_KEYWORD_RESEARCH_QUOTA_GOOGLE,
              quotaBingKwResearch: FREE_KEYWORD_RESEARCH_QUOTA_BING,
              quotaKwResearch: FREE_KEYWORD_RESEARCH_QUOTA,
              quotaSpyAdsKw: FREE_KEYWORD_RESEARCH_QUOTA_SPY_ADS,
              purchaseDate: Timestamp.fromDate(moment().toDate()),
              // expiryDate: Timestamp.fromDate(expiryData.toDate()),
              paymentStatus: 'paid', // "paid", "open"
              webHook: true,
              // zipCode: paymentUser.zipCode.value,
              // country: countrySelected.countryCode,
              // currencyCode: countrySelected.currencyCode,
            },
          })
          const docRef = doc(Database, 'users', user.uid)
          const docSnap = await getDoc(docRef)
          const data = docSnap.data()
          data.uid = user.uid
          user.accountData = data
        }

        // user.accountType = "PREMIUM";
        accountType = getUserAccountPlan(user.accountData)
        // console.log("accountType:", accountType)

        if (accountType === 'EXPIRED') {
          user.accountType = 'FREE'
          notification['error']({
            message: 'Your plan is expired!',
          })
        } else {
          user.accountType = accountType
        }

        setIsAuth(user)
        setLoading(false)
        loadNotifications()
        // history.push('/dashboard');
      } else {
        setIsAuth(null)
        // history.push('/')
        setLoading(false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // console.log("notifications USEEFFECT")

    if (IsAuth && notifications) {
      const { uid } = IsAuth

      let unreadCountVar = 0
      let unreadMessageArrayVar = []

      notifications.map((item) => {
        if (!item.readByUser || !item.readByUser.includes(uid)) {
          unreadCountVar++
          unreadMessageArrayVar.push(item.id)
        }
        return item
      })

      setUnreadCount(unreadCountVar)
      setUnreadMessageArray(unreadMessageArrayVar)
      // console.log("unreadCount:", unreadCountVar);
      // console.log("unreadMessageArray:", unreadMessageArrayVar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  const loadNotifications = async () => {
    const q = query(collection(Database, 'notifications'))
    const querySnapshot = await getDocs(q)

    setNotifications(
      querySnapshot.docs.map((doc) => {
        const data = doc.data()
        const dateCreated = moment(data.createdDate.toDate()).fromNow()
        return {
          title: data.title,
          description: data.description,
          createdDate: dateCreated,
          id: doc.id,
          readByUser: data.readByUser,
        }
      }),
    )
  }

  if (Loading) {
    return (
      <Spin
        className="fixed-center"
        spinning={Loading}
        tip=""
        size="large"
      ></Spin>
    )
  } else {
    return (
      <React.Fragment>
        {!IsAuth ? (
          <Switch>
            <Route exact path={['/', '/login']} component={Login} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/register" component={Register} />
            <Redirect to="/" />
          </Switch>
        ) : (
          <React.Fragment>
              <UserContext.Provider value={IsAuth}>
              <AppContext.Provider
                value={{ loadingFullScreen, setLoadingFullScreen }}
                >
                <div style={{ maxHeight: '70px' }}>
                  <Col span={24} style={{ backgroundColor: 'white' }}>
                    <NavBar
                      // user={IsAuth}
                      notifications={{
                        notifications: notifications,
                        unreadCount: unreadCount,
                        unreadMessageArray: unreadMessageArray,
                        setUnreadCount: setUnreadCount,
                        setUnreadMessageArray: setUnreadMessageArray,
                      }}
                    />
                  </Col>
                </div>
                <div className="float-container">
                  <Spin
                    spinning={loadingFullScreen}
                    tip="Loading..."
                    size="large"
                  >
                    {/* <div className="page-wrap"> */}
                    <div className="float-child child-one">
                      <Sidebar user={IsAuth} />
                    </div>
                    <div className="float-child child-two _main_app_container">
                      <AppRoutes user={IsAuth} />
                    </div>
                    {/* </div> */}
                    {/* <footer className="site-footer text-center py-3">
                                            © 2023. Boost with HMA an Advertising Marketing Application by <a className="xtooltip tooltipstered" href="https://www.marketingwithhma.com" target="_blank" rel="noreferrer">marketingwithhma.com</a>
                                        </footer> */}
                    </Spin>
                  </div>
                  
                </AppContext.Provider>
            </UserContext.Provider>
            <Footer />
            </React.Fragment>
            
        )}
      </React.Fragment>
    )
  }
}
export default App
