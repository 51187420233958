import { Redirect, Route, Switch } from 'react-router-dom'

import Dashboard from './components/dashboard/Dashboard'
import FindFbInterests from './components/fbHiddenInterest/findFbInterests'
import ListFbCollections from './components/fbHiddenInterest/listFbCollections'
import ViewCollections from './components/fbHiddenInterest/viewCollections'

import ShoppingAdsListing from './components/ShoppingAdsListing'
import YoutubeAds from './components/YoutubeAds'
import YoutubeAdsDetails from './components/YoutubeAds/details'
import AiAdsGenerator from './components/aiAds/AiAdsGenerator'
import AiAdsCollections from './components/aiAds/listCollections'
import AiAdsViewCollections from './components/aiAds/viewCollections'
import SpyAds from './components/keywordSearch/SpyAds'
import BingAdsPlanner from './components/keywordSearch/bingAdsPlanner'
import GoogleAdsPlanner from './components/keywordSearch/googleAdsPlanner'
import KeywordSearch from './components/keywordSearch/keywordSearch'
import KeywordSearchCollections from './components/keywordSearch/listCollections'
import ViewBingCollection from './components/keywordSearch/viewBingCollections'
import KeywordSearchViewCollection from './components/keywordSearch/viewCollections'
import ViewGoogleCollection from './components/keywordSearch/viewGoogleCollections'

import EcommerceRoasAnalysis from './components/ecommerceCalculator/roasAnalysis'
import EcommerceRoasMaximizer from './components/ecommerceCalculator/roasMaximizer'

import LeadsCampaignGoals from './components/leadsCalculator/LeadsCampaignGoals'
import LeadsRoasAnalysis from './components/leadsCalculator/LeadsRoasAnalysis'
import LeadsRoasMaximizer from './components/leadsCalculator/LeadsRoasMaximizer'
import Facebookgradel from './components/Gradel/Facebookgradel'
import GoogleGradel from './components/Gradel/GoogleGradel'
import GoogleAnalytics from './components/Gradel/GoogleAnalytics'

import AdminDashboard from './components/Admin/Dashboard/Dashboard'
import Notifications from './components/Admin/Notifications'
import Users from './components/Admin/Users'
import UserDetails from './components/Admin/Users/UserDetails'
import Plans from './components/Plans'
import Profile from './components/Profile'
import ListBingCollections from './components/keywordSearch/listBingCollections'
import ListGoogleCollections from './components/keywordSearch/listGoogleCollections'

const NotFound = () => {
  return <h2 style={{ marginLeft: '30px' }}>Page not found</h2>
}

const AdminRoute = ({ component, user, ...args }) => {
  return user.email === 'srikar712@gmail.com' ? (
    <Route component={component} {...args} />
  ) : (
    <Route component={NotFound} />
  )
}

const PremiumRoute = ({ component, user, ...args }) => {
  return user.accountType !== 'FREE' ? (
    <Route component={component} {...args} />
  ) : (
    <Route component={NotFound} />
  )
}

export default function Routes({ user, notifications }) {
  return (
    <div style={{ padding: user ? '0 0 30px' : '0' }}>
      <Switch>
        <Route exact path="/" component={Dashboard} />

        {/* Admin Routes Starts */}
        <AdminRoute
          user={user}
          exact
          path={['/admin', '/admin/dashboard']}
          component={AdminDashboard}
        />
        <AdminRoute user={user} exact path="/admin/users" component={Users} />
        <AdminRoute
          user={user}
          exact
          path="/admin/users/:id"
          component={UserDetails}
        />
        <AdminRoute
          user={user}
          exact
          path="/admin/notifications"
          component={Notifications}
        />
        {/* Admin Routes Ends */}

        <Route exact path="/dashboard" component={Dashboard} />
        <Route
          exact
          path="/facebook-hidden-interests"
          render={(props) => <FindFbInterests user={user} {...props} />}
        />
        <PremiumRoute
          user={user}
          exact
          path="/facebook-hidden-interests-collection"
          component={ListFbCollections}
        />
        <PremiumRoute
          user={user}
          exact
          path="/facebook-hidden-interests-collection/:id"
          component={ViewCollections}
        />

        {/* Keywords-Search */}
        {/* GOOGLE  (previously) */}
        <Route
          exact
          path="/related-keywords-search"
          render={(props) => (
            <KeywordSearch
              searchType="RELATED_KEYWORDS"
              user={user}
              {...props}
            />
          )}
        />
        {/* YOUTUBE (previously) */}
        <Route
          exact
          path="/keyword-suggestions-search"
          render={(props) => (
            <KeywordSearch
              searchType="KEYWORD_SUGGESTIONS"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/google-ads-search-volume"
          render={(props) => (
            <GoogleAdsPlanner
              searchType="SEARCH_VOLUME"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/google-ads-keywords-site"
          render={(props) => (
            <GoogleAdsPlanner
              searchType="KEYWORDS_SITE"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/google-ads-keywords-for-keywords"
          render={(props) => (
            <GoogleAdsPlanner
              searchType="KEYWORDS_FOR_KEYWORDS"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/google-ads-keywords-traffic"
          render={(props) => (
            <GoogleAdsPlanner
              searchType="KEYWORDS_ADS_TRAFFIC"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/bing-ads-search-volume"
          render={(props) => (
            <BingAdsPlanner
              searchType="BING_SEARCH_VOLUME"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/bing-ads-keywords-site"
          render={(props) => (
            <BingAdsPlanner
              searchType="BING_KEYWORDS_SITE"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/bing-ads-keywords-for-keywords"
          render={(props) => (
            <BingAdsPlanner
              searchType="BING_KEYWORDS_FOR_KEYWORDS"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/search-ads"
          render={(props) => <SpyAds user={user} {...props} />}
        />

        <Route
          exact
          path="/google-shopping-ads"
          render={(props) => (
            <ShoppingAdsListing
              searchType="GOOGLE_SHOPPING"
              user={user}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/amazon-listing"
          render={(props) => (
            <ShoppingAdsListing
              searchType="AMAZON_LISTING"
              user={user}
              {...props}
            />
          )}
        />

        <Route
          exact
          path="/youtube-ads/:id"
          render={(props) => <YoutubeAdsDetails user={user} {...props} />}
        />
        {/* <Route exact path="/youtube-ads/:id" render={(props) => (<YoutubeAds user={user} {...props} />)} /> */}

        <Route
          exact
          path="/youtube-ads"
          render={(props) => (
            <YoutubeAds searchType="YOUTUBE" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/google-ecom-ads"
          render={(props) => (
            <YoutubeAds searchType="GOOGLE_ECOM" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/google-ads"
          render={(props) => (
            <YoutubeAds searchType="GOOGLE_ADS" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/display-ads"
          render={(props) => (
            <YoutubeAds searchType="DISPLAY_ADS" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/flipkart-ads"
          render={(props) => (
            <YoutubeAds searchType="FLIPKART_ADS" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/reddit-ads"
          render={(props) => (
            <YoutubeAds searchType="REDDIT_ADS" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/linkedin-ads"
          render={(props) => (
            <YoutubeAds searchType="LINKEDIN_ADS" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/amazon-ads"
          render={(props) => (
            <YoutubeAds searchType="AMAZON_ADS" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/facebook-ads"
          render={(props) => (
            <YoutubeAds searchType="FACEBOOK_ADS" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/bing-ecom-ads"
          render={(props) => (
            <YoutubeAds searchType="BING_ECOM_ADS" user={user} {...props} />
          )}
        />

        <Route
          exact
          path="/bing-ads"
          render={(props) => (
            <YoutubeAds searchType="BING_ADS" user={user} {...props} />
          )}
        />

        <PremiumRoute
          user={user}
          exact
          path="/keywords-search-collection"
          component={KeywordSearchCollections}
        />
        <PremiumRoute
          user={user}
          exact
          path="/keywords-search-collection/:id"
          component={KeywordSearchViewCollection}
        />
        <PremiumRoute
          user={user}
          exact
          path="/google-search-collection"
          component={ListGoogleCollections}
        />
        <PremiumRoute
          user={user}
          exact
          path="/google-search-collection/:id"
          component={ViewGoogleCollection}
        />
        <PremiumRoute
          user={user}
          exact
          path="/bing-search-collection"
          component={ListBingCollections}
        />
        <PremiumRoute
          user={user}
          exact
          path="/bing-search-collection/:id"
          component={ViewBingCollection}
        />

        {/* Ads-Copy-Generation */}
        <Route
          exact
          path="/google-ai-ads-generation"
          key="GOOGLE"
          render={(props) => (
            <AiAdsGenerator searchType="GOOGLE" user={user} {...props} />
          )}
        />
        <Route
          exact
          path="/facebook-ai-ads-standard"
          key="FACEBOOK"
          render={(props) => (
            <AiAdsGenerator searchType="FACEBOOK" user={user} {...props} />
          )}
        />
        <Route
          exact
          path="/facebook-ai-ads-advanced"
          key="FACEBOOK_ADVANCED"
          render={(props) => (
            <AiAdsGenerator
              searchType="FACEBOOK_ADVANCED"
              user={user}
              {...props}
            />
          )}
        />
        <Route
          exact
          path="/facebook-ai-ads-headline"
          key="FACEBOOK_HEADLINE"
          render={(props) => (
            <AiAdsGenerator
              searchType="FACEBOOK_HEADLINE"
              user={user}
              {...props}
            />
          )}
        />
        <PremiumRoute
          user={user}
          exact
          path="/ai-ads-collection"
          component={AiAdsCollections}
        />
        <PremiumRoute
          user={user}
          exact
          path="/ai-ads-collection/:id"
          component={AiAdsViewCollections}
        />

        {/* Eccomerce Calculator */}
        <Route
          exact
          path="/e-commerce-roas-maximizer"
          component={EcommerceRoasMaximizer}
        />
        <Route
          exact
          path="/e-commerce-roas-analysis"
          component={EcommerceRoasAnalysis}
        />

        {/* Leads Calculator */}
        <Route
          exact
          path="/leads-campaign-goals"
          component={LeadsCampaignGoals}
        />
        <Route
          exact
          path="/leads-roas-maximizer"
          component={LeadsRoasMaximizer}
        />
        <Route
          exact
          path="/leads-roas-analysis"
          component={LeadsRoasAnalysis}
        />

        {/* Facebook Performance Gradel */}
        <Route
          exact
          path="/leads-facebook-gradel"
          component={Facebookgradel}
        />

        <Route
          exact
          path="/leads-google-gradel"
          component={GoogleGradel}
        /> 
        
        <Route
          exact
          path="/leads-google-analytics"
          component={GoogleAnalytics}
        /> 

        {/* <Route exact path={["/login", "/register", "/reset-password"]} component={Dashboard} /> */}

        <Route exact path="/profile" component={Profile} />
        <Route exact path="/plans" component={Plans} />

        <Route exact path={['/login', '/register', '/reset-password']}>
          <Redirect to="/dashboard" />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </div>
  )
}
