import React from 'react';
import { Line } from '@ant-design/charts';

const DualLineChart = ({ data, type, cat1, cat2, Xval, Yval }) => {

  let transformedData = [];

  if (type === "Facebook") {
    transformedData = data.flatMap(item => [
      { age: item.age, value: parseInt(item.clicks), category: cat1 },
      { age: item.age, value: parseInt(item.link_clicks), category: cat2 }
    ]);
  } else { 
    transformedData = data.flatMap(item => [
      { age: item.dimensionValues[0].value, value: parseInt(item.metricValues[0].value), category: cat1 },
      { age: item.dimensionValues[0].value, value: parseInt(item.metricValues[1].value), category: cat2 },
    ]);
  }

  const config = {
    data: transformedData,
    xField: 'age',
    yField: 'value',
    seriesField: 'category',
    color: ({ category }) => {
      if (category === cat1) {
        return '#118DFF'; // Blue for the first line
      }
      return '#12239E'; // Violet for the second line
    },
    xAxis: {
      title: {
        text: Xval,
        style: { fontWeight: 800, fontSize: 14 }
      },
    },
    yAxis: {
      title: {
        text: Yval,
        style: { fontWeight: 800, fontSize: 14 }
      },
      label: {
        formatter: value => value,  // Display values on the Y-axis
      },
    },
    label: false, // Turn off data labels on the chart
    point: {
      size: 5,
      shape: 'circle',
      style: ({ category }) => {
        if (category === cat1) {
          return {
            fill: '#E66C37', // Orange marker for first line
            stroke: '#E66C37',
          };
        }
        return {
          fill: '#E1C233', // Yellow marker for second line
          stroke: '#E1C233',
        };
      },
    },
    tooltip: {
      showMarkers: true,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [{ type: 'marker-active' }],
  };

  return (
    <div style={{ width: "95%" }}>
      <Line {...config} />
    </div>
  );
};

export default DualLineChart;
