import { Button as AntButton, Row, notification } from 'antd'
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
} from 'firebase/firestore'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { PAGE_HEAD_TITLE } from '../../constants'
import AppContext from '../../context/AppContext'
import { Database } from '../../firebase'
import {
  getAdSpyderDetail,
  getSpyAdsDataApi,
} from '../../services/youtubeAdsApiService'
import BunnyPlayer from '../BunnyPlayer'
// import { pleaseSubscribe, humanReadableNumber } from "../../utils/Helpers";
import {
  checkQuotaValidation,
  updateRequestUserQuota,
} from '../../services/quotaCheckService'
import SearchInput from '../SearchInput'
import RadioButton from '../SearchRadioButton'
import AdsSpyLocationDropdown, {
  locationOptions,
} from '../adsSpyLocationDropdown'
import AdsSpySortDropdown, { sortOptions } from '../adsSpySortDropdown'
import '../keywordSearch/style.css'
import AmazonAd from './amazonAd'
import BingAd from './bingAd'
import BingEcomAd from './bingecomAd'
import MyCollection from './collection'
import AdsDetail from './details'
import DisplayAd from './displayAd'
import FacebookAd from './facebook'
import FlipkartAd from './flipkartAd'
import GoogleAd from './googleAd'
import GoogleEcomAd from './googleEcomAd'
import LinkedInAd from './linkedinAd'
import RedditAd from './redditAd'
import YoutubeAd from './youtubeAd'
const YoutubeAds = (props) => {
  const { setLoadingFullScreen } = useContext(AppContext)

  const searchType = props.searchType
  const authUser = props.user
  const { uid, accountType } = authUser

  const [inputKey, setInputKey] = useState('')
  const [adsDetails, setAdsDetails] = useState(null)
  const [viewMyCollection, setViewMyCollection] = useState(false)
  const [searchData, setSearchData] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(locationOptions[0])
  const [selectedSortKey, setSelectedSortKey] = useState(sortOptions[2])
  const [selectedRadio, setSelectedRadio] = useState('Broad')
  const [selectedCallType, setSelectedCallType] = useState('Keyword')
  const urlSearchTypes = [
    'GOOGLE_ADS',
    'GOOGLE_ECOM',
    'FACEBOOK_ADS',
    'BING_ECOM_ADS',
    'BING_ADS',
  ]

  if (!urlSearchTypes.includes) {
    setSelectedCallType('Keyword')
  }
  useEffect(() => {}, []) //eslint-disable-line

  useEffect(() => {
    document.title = `${
      searchType.toLowerCase().charAt(0).toUpperCase() +
      searchType.toLowerCase().slice(1).replace('_', ' ')
    } ${PAGE_HEAD_TITLE}`

    // reset to default state. If searchType changes
    setInputKey('')
    setAdsDetails(null)
    setViewMyCollection(false)
    setSearchData([])
    // setsearchCountries([])
    setSelectedCountry({})
    setSelectedSortKey({})
    setSelectedCallType('Keyword')
  }, [searchType]) //eslint-disable-line

  const findKeywords = async (type) => {
    if (!inputKey) {
      notification['error']({ message: 'Please enter search keyword!' })
    } else {
      setLoadingFullScreen(true)

      const checkResponse = await checkQuotaValidation(
        'YOUTUBE_ADS',
        authUser,
        {
          searchType,
        },
      )
      if (checkResponse.valid === false) {
        setLoadingFullScreen(false)
      } else {
        let page = 1
        if (type === 'loadMore') {
          page = searchData.length / 20 + 1
        }
        const dts = {
          query: inputKey,
          page: page,
          type: searchType,
          callType: selectedRadio,
          urlCallType: selectedCallType,
          ...(selectedCountry.country && {
            country: selectedCountry && selectedCountry.country,
          }),
          ...(selectedSortKey.value && {
            sortKey: selectedSortKey && selectedSortKey.value,
          }),
        }

        const data = await getSpyAdsDataApi(dts)

        if (data) {
          if (data.results && data.results.length) {
            if (type === 'search') {
              setSearchData(data.results)
            }
            if (type === 'loadMore') {
              setSearchData([...searchData, ...data.results])
            }
            updateRequestUserQuota('YOUTUBE_ADS', authUser, {
              searchType,
              numResults: data.results.length,
            })
          } else {
            if (type !== 'loadMore') {
              setSearchData([])
              notification['error']({
                message: 'No results!!',
                placement: 'topRight',
              })
            } else {
              notification['error']({ message: 'No more records found!' })
            }
          }
        } else {
          notification['error']({
            message: 'No results!!',
            placement: 'topRight',
          })
        }

        setLoadingFullScreen(false)
      }
    }
  }

  const fetchDetails = async (id) => {
    setLoadingFullScreen(true)

    const dts = { id: id }

    const data = await getAdSpyderDetail(dts)
    if (data && data.result && data.success === true) {
      setAdsDetails(data)
    } else {
      setAdsDetails(null)
      notification['error']({
        message: 'No result found!!',
        placement: 'topRight',
      })
    }

    setLoadingFullScreen(false)
  }

  const handleRemoveFromFav = async (item) => {
    setLoadingFullScreen(true)

    await deleteDoc(doc(Database, 'youtubeAdsCollection', item.docRefId))
    const newData = searchData.map((i) => {
      if (i.id === item.id) {
        delete item.docRefId
        i.isFav = false
      }
      return i
    })
    setSearchData(newData)
    notification['success']({
      message: 'Removed from collection!!',
      placement: 'topRight',
    })
    setLoadingFullScreen(false)
  }

  const handleAddToFav = async (item) => {
    setLoadingFullScreen(true)

    const dataString = JSON.stringify(item)
    const payload = {
      userId: uid,
      itemId: item.id,
      searchKey: inputKey,
      searchType: searchType,
      createdDate: Timestamp.fromDate(moment().toDate()),
      adsData: dataString,
    }

    const docRef = await addDoc(
      collection(Database, 'youtubeAdsCollection'),
      payload,
    )

    if (docRef.id) {
      const newData = searchData.map((i) => {
        if (i.id === item.id) {
          i.docRefId = docRef.id
          i.isFav = true
        }
        return i
      })
      setSearchData(newData)
      notification['success']({
        message: 'Added to collection!!',
        placement: 'topRight',
      })
    } else {
      alert('SOMETHING WENT WRONG')
    }
    setLoadingFullScreen(false)
  }

  const clearSearch = () => {
    setAdsDetails(null)
  }

  if (adsDetails) {
    return (
      <AdsDetail
        searchType={searchType}
        data={adsDetails}
        handleGoBack={() => clearSearch()}
      />
    )
  }

  if (viewMyCollection) {
    return (
      <MyCollection
        searchType={searchType}
        user={authUser}
        handleGoBack={() => {
          setViewMyCollection(false)
        }}
      />
    )
  }

  // const onClickMenu = (item) => {
  //   setSelectedCountry(item)
  // }

  let placeholder = 'Enter keyword'
  if (searchType === 'DISPLAY_ADS' || selectedCallType === 'URL') {
    placeholder = 'Enter keyword/URL'
  }

  const selectedSearchRadio = (value) => {
    setSelectedRadio(value)
  }

  const selectedCallTypeRadio = (value) => {
    setSelectedCallType(value)
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <BunnyPlayer type={searchType} />
        {urlSearchTypes.includes(searchType) ? (
          <div className="searchRadioButton" style={{ marginTop: '30px' }}>
            <RadioButton
              options={['Keyword', 'URL']}
              value={selectedCallType}
              onChange={selectedCallTypeRadio}
            />
          </div>
        ) : (
          <></>
        )}
        {urlSearchTypes.includes(searchType) ? (
          <Row
            className="searchfbInterest"
            style={{ margin: '50px auto 20px', marginTop: '20px' }}
          >
            {' '}
            <SearchInput
              inputKey={inputKey}
              setInputKey={setInputKey}
              findKeywords={findKeywords}
              accountType={accountType}
              placeholder={placeholder}
            />
          </Row>
        ) : (
          <Row
            className="searchfbInterest"
            style={{ margin: '50px auto 20px' }}
          >
            {' '}
            <SearchInput
              inputKey={inputKey}
              setInputKey={setInputKey}
              findKeywords={findKeywords}
              accountType={accountType}
              placeholder={placeholder}
            />
          </Row>
        )}

        <div style={{ position: 'relative' }} className="">
          <span
            className="view-my-collection"
            onClick={() => setViewMyCollection(true)}
          >
            View My Collection
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <AdsSpyLocationDropdown
            defaultValue={selectedCountry}
            onChange={(value) => {
              setSelectedCountry(value)
            }}
          />
          <AdsSpySortDropdown
            defaultValue={selectedSortKey}
            onChange={(value) => {
              setSelectedSortKey(value)
            }}
          />
        </div>
        {searchType !== 'DISPLAY_ADS' &&
        /* urlSearchTypes.includes(searchType) && */
        selectedCallType === 'Keyword' ? (
          <div className="searchRadioButton">
            <RadioButton
              options={['Broad', 'Phrase']}
              value={selectedRadio}
              onChange={selectedSearchRadio}
            />
          </div>
        ) : (
          <></>
        )}
        {searchData.length ? (
          <div className="row">
            {searchData.map((item, index) => {
              if (searchType === 'YOUTUBE') {
                return (
                  <YoutubeAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                    fetchDetails={fetchDetails}
                  />
                )
              } else if (searchType === 'GOOGLE_ECOM') {
                return (
                  <GoogleEcomAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'GOOGLE_ADS') {
                return (
                  <GoogleAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'DISPLAY_ADS') {
                return (
                  <DisplayAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'FLIPKART_ADS') {
                return (
                  <FlipkartAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'REDDIT_ADS') {
                return (
                  <RedditAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'LINKEDIN_ADS') {
                return (
                  <LinkedInAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'AMAZON_ADS') {
                return (
                  <AmazonAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'FACEBOOK_ADS') {
                return (
                  <FacebookAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'BING_ECOM_ADS') {
                return (
                  <BingEcomAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              } else if (searchType === 'BING_ADS') {
                return (
                  <BingAd
                    item={item}
                    index={index}
                    handleAddToFav={handleAddToFav}
                    handleRemoveFromFav={handleRemoveFromFav}
                  />
                )
              }

              return null
            })}
          </div>
        ) : null}
        {searchData.length ? (
          <div className="pagination-wrapper">
            <AntButton
              className="ml-5 mt-2"
              onClick={() => findKeywords('loadMore')}
            >
              Load more results..
            </AntButton>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default withRouter(YoutubeAds)
