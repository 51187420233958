import {
  FontColorsOutlined,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { Button, Input, Space, notification } from 'antd'
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
} from 'firebase/auth'
import { Timestamp, doc, setDoc } from 'firebase/firestore'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  FREE_KEYWORD_REQUEST_QUOTA_AI,
  FREE_KEYWORD_RESEARCH_QUOTA,
  FREE_KEYWORD_RESEARCH_QUOTA_BING,
  FREE_KEYWORD_RESEARCH_QUOTA_GOOGLE,
  FREE_KEYWORD_RESEARCH_QUOTA_SPY_ADS,
  PAGE_HEAD_TITLE,
} from '../../constants'
import { Database } from '../../firebase'
import logo from '../../styles/images/logo.svg'
import './auth.css'

const Register = (props) => {
  // const history = useHistory();

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [userPasswordConfirm, setUserPasswordConfirm] = useState('')
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [formError, setFormError] = useState()

  useEffect(() => {
    document.title = `Register ${PAGE_HEAD_TITLE}`
    document.body.classList.add('bodyoverflowunset')
    return () => {
      document.body.classList.remove('bodyoverflowunset')
    }
  }, [])

  const handleRegistration = async () => {
    setFormError('')
    let errorMessage = ''
    /* eslint-disable-next-line */
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    if (name === '') {
      errorMessage = 'Please enter your name!'
    } else if (userEmail === '') {
      errorMessage = 'Please enter your email!'
    } else if (regex.test(userEmail) === false) {
      errorMessage = 'Please enter valid email!'
    } else if (userPassword === '') {
      errorMessage = 'Please enter password!'
    } else if (userPasswordConfirm === '') {
      errorMessage = 'Please confirm your password!'
    } else if (userPassword !== userPasswordConfirm) {
      errorMessage = 'Password do not match!'
    } else if (!acceptTerms) {
      errorMessage = 'Please accept terms and conditions!'
    }

    if (errorMessage) {
      setFormError(errorMessage)
      openNotificationWithIcon('error', errorMessage)
    } else {
      // console.log("check email");
      setLoading(true)
      // const data = await axios.post(EMAIL_VALIDATE_CLEAN_API_URL,
      //     { "email": [userEmail] }, {
      //     headers: {
      //         'Content-Type': 'application/json',
      //         'Authorization': `Bearer ${EMAIL_VALIDATE_CLEAN_API_KEY}`
      //     }
      // }).then(response => response.data)
      //     .catch((error) => {
      //         setLoading(false);
      //         // console.log("ERROR:", error);
      //         openNotificationWithIcon('error', "Something went wrong, Please try again later!");
      //         // errorData = error;
      //     });

      // console.log("data:", data)
      // if (data && data.result && data.result[0] && data.result[0].emailStatus && data.result[0].emailStatus.status === "valid") {
      // console.log("data.result[0].emailStatus:", data.result[0].emailStatus.status)
      // console.log("VALID");
      // return data;

      const auth = getAuth()
      createUserWithEmailAndPassword(auth, userEmail, userPassword)
        .then(async (userCredential) => {
          // Signed in
          var { uid } = userCredential.user

          updateProfile(auth.currentUser, {
            displayName: name,
          })
            .then(() => {
              console.log('Profile updated!')
            })
            .catch((error) => {
              console.log('Profile update error!')
            })

          await setDoc(doc(Database, 'users', uid), {
            email: userEmail,
            name: name,
            createdDate: Timestamp.fromDate(moment().toDate()),
            subscriptionPlan: {
              price: 0,
              planId: 'FREE',
              planMonthlyYearly: 'YEARLY',
              validityDays: 365,
              quotaAiRequest: FREE_KEYWORD_REQUEST_QUOTA_AI,
              quotaGoogleKwResearch: FREE_KEYWORD_RESEARCH_QUOTA_GOOGLE,
              quotaBingKwResearch: FREE_KEYWORD_RESEARCH_QUOTA_BING,
              quotaKwResearch: FREE_KEYWORD_RESEARCH_QUOTA,
              quotaSpyAdsKw: FREE_KEYWORD_RESEARCH_QUOTA_SPY_ADS,
              purchaseDate: Timestamp.fromDate(moment().toDate()),
              // expiryDate: Timestamp.fromDate(expiryData.toDate()),
              paymentStatus: 'paid', // "paid", "open"
              webHook: true,
              // zipCode: paymentUser.zipCode.value,
              // country: countrySelected.countryCode,
              // currencyCode: countrySelected.currencyCode,
            },
          })

          // history.push('/plans');
          setLoading(false)
          openNotificationWithIcon('success', 'Welcome to Boost With HMA')
        })
        .catch((error) => {
          const errorCode = error.code
          errorMessage = error.message
          // setFormError(errorMessage);
          setLoading(false)
          openNotificationWithIcon('error', errorCode)
          console.log('errorCode', errorCode)
          console.log('errorMessage', errorMessage)
        })
      // } else {
      //     setLoading(false);
      //     // console.log("IN VALID email");
      //     openNotificationWithIcon('error', "Please enter valid email address!");
      //     // console.log("errorData:", errorData)
      //     // return errorData;
      // }
    }
  }

  // const handleGoogleLogin = () => {

  //     const auth = getAuth();
  //     const provider = new GoogleAuthProvider();

  //     signInWithPopup(auth, provider)
  //         .then(async (result) => {
  //             // this is handled on app.js - onAUthStateChange
  //             // This gives you a Google Access Token. You can use it to access the Google API.
  //             // const credential = GoogleAuthProvider.credentialFromResult(result);
  //             // console.log('credential', credential)
  //             // const token = credential.accessToken;
  //             // The signed-in user info.
  //             // const user = result.user;
  //             // console.log('user', user)

  //             // const docRef = doc(Database, "users", user.uid);
  //             // const docSnap = await getDoc(docRef);

  //             // if (docSnap.exists()) {
  //             //     await updateDoc(docRef, {
  //             //         name: user.displayName,
  //             //         updatedDate: Timestamp.fromDate(moment().toDate()),
  //             //     });
  //             // } else {
  //             //     await setDoc(doc(Database, "users", user.uid), {
  //             //         email: user.email,
  //             //         name: user.displayName,
  //             //         createdDate: Timestamp.fromDate(moment().toDate()),
  //             //     });
  //             // }
  //             // ...
  //             history.push('/dashboard')
  //         }).catch((error) => {
  //             // Handle Errors here.
  //             const errorCode = error.code;
  //             const errorMessage = error.message;
  //             // The email of the user's account used.
  //             const email = error.email;
  //             // The AuthCredential type that was used.
  //             const credential = GoogleAuthProvider.credentialFromError(error);
  //             console.log('errorCode', errorCode)
  //             console.log('errorMessage', errorMessage)
  //             console.log('email', email)
  //             console.log('credential', credential)
  //             openNotificationWithIcon('error', errorCode)
  //         });
  // }

  const openNotificationWithIcon = (type, msg) => {
    if (msg === 'auth/popup-closed-by-user') {
      notification[type]({
        message: 'Login cancelled!',
      })
      setFormError('Login cancelled!')
    } else {
      type === 'error' && setFormError(msg)
      notification[type]({
        message: msg,
      })
    }
  }

  return (
    <>
      <main className="_main_app_container auth register">
        <Space />
        <div className="main">
          <div className="text-center mb-4">
            <img src={logo} alt="Boost with HMA" className="logo auth-logo" />
          </div>
          <section className="signup">
            <div className="container">
              <div className="signup-content">
                <form
                  id="signup-form"
                  className="signup-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <h2 className="form-title" style={{ marginBottom: '10px' }}>
                    Sign Up, It's Free
                  </h2>
                  <span className="invalid-feedback d-block">
                    {formError}&nbsp;
                  </span>
                  <div className="form-group">
                    <Input
                      type="text"
                      className="form-input"
                      placeholder="Please enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      prefix={
                        <FontColorsOutlined
                          style={{ fontSize: 20, marginRight: 10 }}
                        />
                      }
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      type="text"
                      className="form-input"
                      placeholder="Please enter your email address"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                      prefix={
                        <MailOutlined
                          style={{ fontSize: 20, marginRight: 10 }}
                        />
                      }
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      type="password"
                      className="form-input"
                      placeholder="Please enter password"
                      value={userPassword}
                      prefix={
                        <LockOutlined
                          style={{ fontSize: 20, marginRight: 10 }}
                        />
                      }
                      onChange={(e) => setUserPassword(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      type="password"
                      className="form-input"
                      placeholder="Please re-enter password"
                      value={userPasswordConfirm}
                      prefix={
                        <LockOutlined
                          style={{ fontSize: 20, marginRight: 10 }}
                        />
                      }
                      onChange={(e) => setUserPasswordConfirm(e.target.value)}
                    />
                  </div>
                  <div className="form-group" style={{ display: 'flex' }}>
                    <input
                      type="checkbox"
                      htmlFor="agree-term"
                      name="agree-term"
                      className="agree-term-check"
                      defaultChecked={acceptTerms}
                      onChange={(e) => setAcceptTerms((value) => !value)}
                    />
                    <label htmlFor="agree-term" className="label-agree-term">
                      <span>
                        <span></span>
                      </span>
                      I agree all statements in{' '}
                      <span className="term-service">Terms of service</span>
                    </label>
                  </div>
                  <div className="form-group text-center mb-0 ">
                    {loading ? (
                      <Button
                        size="large"
                        onClick={() => {}}
                        type="primary w-100"
                      >
                        CHECKING..{' '}
                        <LogoutOutlined style={{ verticalAlign: 'middle' }} />
                      </Button>
                    ) : (
                      <Button
                        size="large"
                        onClick={handleRegistration}
                        type="primary w-100"
                      >
                        REGISTER{' '}
                        <LogoutOutlined style={{ verticalAlign: 'middle' }} />
                      </Button>
                    )}
                    {/* <div style={{ margin: '10px 0' }}>OR</div>

                                        <Button size='large' onClick={handleGoogleLogin} type='primary w-100'>Continue with Google <GoogleOutlined style={{ verticalAlign: 'middle' }} /></Button>

                                        <Button size='large' onClick={() => { }} type='primary w-100' style={{ marginTop: '10px' }} disabled>Continue with Facebook <FacebookOutlined style={{ verticalAlign: 'middle' }} /></Button> */}
                  </div>
                </form>
              </div>
              <div className="text-center mt-4">
                Already have an account ?{' '}
                <Link to="/login" className="text-info">
                  Login here
                </Link>
              </div>
            </div>
          </section>
        </div>
      </main>
      <footer className="footer-content">
      © 2024 All Rights Reserved. HMA TECH & MARKETING SOLUTIONS LLC | <a href="https://www.marketingwithhma.com" target="_blank" rel="noreferrer">www.marketingwithhma.com </a>
      | Privacy Policy | Terms & Conditions
    </footer>
    </>
  )
}

export default Register
