import { NotificationOutlined, UserOutlined } from '@ant-design/icons'
import { Divider, Menu, Popover } from 'antd'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as S2 } from '../../styles/icons/S2.svg'
import { ReactComponent as S3 } from '../../styles/icons/S3.svg'
import { ReactComponent as AiAds } from '../../styles/icons/ai-ads.svg'
import UserContext from '../../userContext'
// import { ReactComponent as S9 } from "../../styles/icons/S9.svg";
// import { ReactComponent as S10 } from "../../styles/icons/S10.svg";
// import { ReactComponent as S11 } from "../../styles/icons/S11.svg";
import { Icon as S12 } from '../../styles/icons/LiveWorkspace'
import { ReactComponent as S13 } from '../../styles/icons/S13.svg'
import Circles from '../../styles/icons/circles.png'
import { ReactComponent as Ecommerce } from '../../styles/icons/ecommerce.svg'
import Arrow from '../../styles/icons/ic_chevron.svg'
import { ReactComponent as Leads } from '../../styles/icons/leads.svg'
import { ReactComponent as SpyAds } from '../../styles/icons/spy-ads.svg'
import Wave from '../../styles/icons/wave.png'
import { pleaseSubscribe } from '../../utils/Helpers'
import './Sidebar.css'

const Sidebar = (props) => {
  const user = useContext(UserContext)

  const accountType = user.accountType

  const adminRoutes = () => {
    return (
      <>
        <Menu.Item key="admin" style={{ color: '#175bcb', fontWeight: 'bold' }}>
          <Link to="/admin/dashboard">Admin Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="users " icon={<UserOutlined />}>
          <Link to="/admin/users">Users</Link>
        </Menu.Item>
        <Menu.Item key="notifications " icon={<NotificationOutlined />}>
          <Link to="/admin/notifications">Notifications</Link>
        </Menu.Item>
      </>
    )
  }

  return (
    <Menu
      style={{
        minHeight: '88vh',
        height: '88vh',
        overflowY: 'auto',
        border: 'none',
        overflowX: 'hidden',
      }}
      mode="inline"
      className="custom-menu"
    >
      {user.email === 'srikar712@gmail.com' ? adminRoutes() : null}

      <Menu.Item key="1" style={{ color: '#175bcb', fontWeight: 'bold' }}>
        <Link to="/dashboard">Dashboard</Link>
      </Menu.Item>
      <Menu.SubMenu key="sub1" icon={<S2 />} title="Facebook Hidden Interests">
        <Menu.Item key="2">
          <Link to="/facebook-hidden-interests">Find Interests</Link>
        </Menu.Item>
        {accountType === 'FREE' ? (
          <Popover content={pleaseSubscribe} className="pl48">
            <Menu.Item key="3" className="grey-out">
              Collections
            </Menu.Item>
          </Popover>
        ) : (
          <Menu.Item key="4">
            <Link to="/facebook-hidden-interests-collection">Collections</Link>
          </Menu.Item>
        )}
      </Menu.SubMenu>
      <Menu.SubMenu key="sub3" icon={<S3 />} title="Keywords Search">
        <Menu.Item key="sub35">
          <Link to="/related-keywords-search">Related Keywords</Link>
        </Menu.Item>
        <Menu.Item key="sub36">
          <Link to="/keyword-suggestions-search">Keyword Suggestions</Link>
        </Menu.Item>
        {accountType === 'FREE' ? (
          <Popover content={pleaseSubscribe} className="pl48">
            <Menu.Item key="sub37" className="grey-out">
              Collections
            </Menu.Item>
          </Popover>
        ) : (
          <Menu.Item key="sub38">
            <Link to="/keywords-search-collection">Collections</Link>
          </Menu.Item>
        )}
      </Menu.SubMenu>

      <Menu.SubMenu key="sub6" icon={<S3 />} title="Google Ads Planner">
        <Menu.Item key="sub61">
          <Link to="/google-ads-search-volume">Search Volume</Link>
        </Menu.Item>
        <Menu.Item key="sub62">
          <Link to="/google-ads-keywords-site">Keyword for Site</Link>
        </Menu.Item>
        <Menu.Item key="sub63">
          <Link to="/google-ads-keywords-for-keywords">
            Keyword for Keywords
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="sub64">
          <Link to="/google-ads-keywords-traffic">Ad Traffic by Keywords</Link>
        </Menu.Item> */}
        {accountType === 'FREE' ? (
          <Popover content={pleaseSubscribe} className="pl48">
            <Menu.Item key="sub65" className="grey-out">
              Collections
            </Menu.Item>
          </Popover>
        ) : (
          <Menu.Item key="sub66">
            <Link to="/google-search-collection">Collections</Link>
          </Menu.Item>
        )}
      </Menu.SubMenu>

      <Menu.SubMenu key="sub7" icon={<S3 />} title="Bing Ads Planner">
        <Menu.Item key="sub71">
          <Link to="/bing-ads-search-volume">Search Volume</Link>
        </Menu.Item>
        <Menu.Item key="sub72">
          <Link to="/bing-ads-keywords-site">Keyword for Site</Link>
        </Menu.Item>
        <Menu.Item key="sub73">
          <Link to="/bing-ads-keywords-for-keywords">Keyword for Keywords</Link>
        </Menu.Item>
        {accountType === 'FREE' ? (
          <Popover content={pleaseSubscribe} className="pl48">
            <Menu.Item key="sub74" className="grey-out">
              Collections
            </Menu.Item>
          </Popover>
        ) : (
          <Menu.Item key="sub75">
            <Link to="/bing-search-collection">Collections</Link>
          </Menu.Item>
        )}
      </Menu.SubMenu>

      <Menu.SubMenu key="sub1313" icon={<SpyAds />} title="Spy Competitors">
        <Menu.Item key="sub384524">
          <Link to="/search-ads">Search Ads</Link>
        </Menu.Item>
        {/* {accountType === "ADMIN" ? */}
        <Menu.Item key="sub384524dsa">
          <Link to="/google-shopping-ads">Google Shopping</Link>
        </Menu.Item>
        <Menu.Item key="amazon-listing">
          <Link to="/amazon-listing">Amazon Listings</Link>
        </Menu.Item>
        <Menu.Item key="spy-shopping-ads">
          <Link to="/youtube-ads">Youtube Ads</Link>
        </Menu.Item>
        <Menu.Item key="google-ecom-ads">
          <Link to="/google-ecom-ads">Google Ecom Ads</Link>
        </Menu.Item>
        <Menu.Item key="google-ads">
          <Link to="/google-ads">Google Ads</Link>
        </Menu.Item>
        <Menu.Item key="display-ads">
          <Link to="/display-ads">Display Ads</Link>
        </Menu.Item>
        <Menu.Item key="flipkart-ads">
          <Link to="/flipkart-ads">Flipkart Ads</Link>
        </Menu.Item>
        <Menu.Item key="reddit-ads">
          <Link to="/reddit-ads">Reddit Ads</Link>
        </Menu.Item>
        <Menu.Item key="linkedin-ads">
          <Link to="/linkedin-ads">LinkedIn Ads</Link>
        </Menu.Item>
        <Menu.Item key="amazon-ads">
          <Link to="/amazon-ads">Amazon Ads</Link>
        </Menu.Item>
        <Menu.Item key="facebook-ads">
          <Link to="/facebook-ads">Facebook Ads</Link>
        </Menu.Item>
        <Menu.Item key="bing-ecom-ads">
          <Link to="/bing-ecom-ads">Bing Ecom Ads</Link>
        </Menu.Item>
        <Menu.Item key="bing-ads">
          <Link to="/bing-ads">Bing Ads</Link>
        </Menu.Item>
        {/* : null} */}
      </Menu.SubMenu>

      {/* <Menu.Item key="sub6666" className="ml10abc" icon={<SpyAds />}>
        <Link to="/search-ads">Spy Ads</Link>
      </Menu.Item> */}

      <Menu.SubMenu key="sub9" icon={<AiAds />} title="AI Ads Generator">
        <Menu.Item key="9">
          <Link to="/google-ai-ads-generation">Google Ads</Link>
        </Menu.Item>

        <Menu.SubMenu key="sub9rere" title="Facebook Ads">
          <Menu.Item key="10fddf">
            <Link to="/facebook-ai-ads-standard">Standard</Link>
          </Menu.Item>
          <Menu.Item key="10dsd">
            <Link to="/facebook-ai-ads-advanced">Advanced</Link>
          </Menu.Item>
          <Menu.Item key="10dsew">
            <Link to="/facebook-ai-ads-headline">Headline</Link>
          </Menu.Item>
        </Menu.SubMenu>

        {accountType === 'FREE' ? (
          <Popover content={pleaseSubscribe} className="pl48">
            <Menu.Item key="11" className="grey-out">
              Collections
            </Menu.Item>
          </Popover>
        ) : (
          <Menu.Item key="12">
            <Link to="/ai-ads-collection">Collections</Link>
          </Menu.Item>
        )}
      </Menu.SubMenu>
      <Menu.SubMenu
        key="sub4"
        icon={<Ecommerce />}
        title="Ecommerce Calculator"
      >
        <Menu.Item key="13">
          <Link to="/e-commerce-roas-maximizer">ROAS Maximizer</Link>
        </Menu.Item>
        <Menu.Item key="14">
          <Link to="/e-commerce-roas-analysis">ROAS Analysis</Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.SubMenu key="sub5" icon={<Leads />} title="Leads Calculator">
        <Menu.Item key="15">
          <Link to="/leads-campaign-goals">Campaign Goals</Link>
        </Menu.Item>
        <Menu.Item key="16">
          <Link to="/leads-roas-maximizer">ROAS Maximizer</Link>
        </Menu.Item>
        <Menu.Item key="17">
          <Link to="/leads-roas-analysis">ROAS Analysis</Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.SubMenu key="sub10" icon={<Leads />} title="Performance Grader">
        <Menu.Item key="18">
          <Link to="/leads-facebook-gradel">Facebook Grader</Link>
        </Menu.Item>
        <Menu.Item key="19">
          <Link to="/leads-google-gradel">Google Grader</Link>
        </Menu.Item>
        <Menu.Item key="20">
          <Link to="/leads-google-analytics">Google Analytics</Link>
        </Menu.Item>
      </Menu.SubMenu>


      <Divider />
      <Menu.Item
        key="training-resources"
        style={{ color: '#175bcb', fontWeight: 'bold' }}
      >
        <Link to="#">Training/Resources</Link>
      </Menu.Item>

      {/* <Menu.Item key="sub6" className="ml10a" icon={<S9 />}>
        <Link to="#">Facebook Ads</Link>
      </Menu.Item>
      <Menu.Item key="sub7" className="ml10a" icon={<S10 />}>
        <Link to="#">Google Ads</Link>
      </Menu.Item>
      <Menu.Item key="sub8" className="ml10a" icon={<S11 />}>
        <Link to="#">Youtube Ads</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="sub99999" className="ml10a" icon={<LinkedinOutlined style={{ color: '#175BCB', padding: '3px' }} />}>
        <Link to="#">LinkedIn Ads</Link>
      </Menu.Item> */}

      {/* <Menu.SubMenu key="sub6" icon={<S9 />} title="Facebook Ads">
        <Menu.Item key="ab">Basics Training</Menu.Item>
        <Menu.Item key="cd">Intermediate Training</Menu.Item>
        {accountType === "FREE" ?
          <Popover content={pleaseSubscribe} className="pl48">
            <Menu.Item key="ef" className="grey-out">Advanced Training</Menu.Item>
          </Popover>
          :
          <Menu.Item key="gh"><Link to="#">Advanced Training</Link></Menu.Item>
        }
      </Menu.SubMenu> */}
      {/* <Menu.SubMenu key="sub7" icon={<S10 />} title="Google Ads ">
        <Menu.Item key="kl">Basics Training</Menu.Item>
        <Menu.Item key="mn">Intermediate Training</Menu.Item>
        {accountType === "FREE" ?
          <Popover content={pleaseSubscribe} className="pl48">
            <Menu.Item key="op" className="grey-out">Advanced Training</Menu.Item>
          </Popover>
          :
          <Menu.Item key="qr"><Link to="#">Advanced Training</Link></Menu.Item>
        }
      </Menu.SubMenu> */}
      {/* <Menu.SubMenu key="sub8" icon={<S11 />} title="Youtube Ads ">
        <Menu.Item key="uv">Basics Training</Menu.Item>
        <Menu.Item key="wx">Intermediate Training</Menu.Item>
        {accountType === "FREE" ?
          <Popover content={pleaseSubscribe} className="pl48">
            <Menu.Item key="yz" className="grey-out">Advanced Training</Menu.Item>
          </Popover>
          :
          <Menu.Item key="abc"><Link to="#">Advanced Training</Link></Menu.Item>
        }
      </Menu.SubMenu> */}

      <Menu.Item
        key="live-workshops"
        className="ml10a"
        icon={<S12 fill="#175BCB" />}
      >
        <Link to="#">Live Workshops</Link>
      </Menu.Item>
      <Menu.Item key="quick-links" icon={<S13 />}>
        Quick Links
      </Menu.Item>
      <div>
        <div className="cust-box-1">
          <span className="box-label">
            Free Performance <br /> Marketing Course
          </span>
          <div className="learn-more-1">
            <Link to="#">
              <span className="label-cust-1">Learn More</span>
              <img src={Arrow} className="ic-chevron-svg" alt="" />
            </Link>
          </div>
          <img src={Circles} className="circles-svg" alt="" />
          <div className="wave-container">
            <img src={Wave} className="wave-svg" alt="" />
          </div>
        </div>
      </div>
    </Menu>
  )
}

export default Sidebar
