import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import { Title } from '../aiAds/AiAdsGenerator';
import '../../app.css';
import { googleAdsApiService } from '../../services/googleApiService';
import { da } from 'date-fns/locale';
import firebase from 'firebase/compat/app';
import { Option } from 'antd/lib/mentions';
import Cookies from 'js-cookie';

const GoogleGradel = () => {

  const [loginState, setloginState] = useState(false);
  const [access_token, setaccessToken] = useState("");
  const [adAccounts, setAdAccounts] = useState([]);
  const [adAccountValue, setAdAccountValue] = useState();
  const [adcamp, setadcamp] = useState([]);

  const google_data = {
    "access_token": "ya29.a0AXooCgtrWFGbiTBWgYABnO7CPxUMHPXMR0_UVfaq4uk2nPNO6kmeKdSVDqaBpRtcPCK7OwdnDe1BYLrtdkiJm8wPql7bu901D57u9rKrC_CiTGGm4oO8AZ0sshMdkwEwx-gJWVEqS4lszgUP7wHXjZhRmiD3HmE408F7aCgYKAQQSARMSFQHGX2MiT3OfVTREDo6PdJv0hh_ZkA0171",
    "refresh_token": "1//04q1BDnaARsVZCgYIARAAGAQSNwF-L9Ir9qzZd3AuMDN9kiYP8TUnA06HUbbDGiiWqhTjvmYiLE_7rfWX0IBNrz4c9WsK1xifXuA",
    "scope": "https://www.googleapis.com/auth/adwords",
    "token_type": "Bearer",
    "expiry_date": 1721988420737
}


const cus_list =[
  {
    resourceName: 'customers/5412366484',
    customerName: 'Business Book'
  },
  {
    resourceName: 'customers/1486834619',
    customerName: 'Error fetching details'
  }
]

const camp_val=[
  {
      "results": [
          {
              "campaign": {
                  "resourceName": "customers/5412366484/campaigns/10993984699",
                  "name": "Daily Tally",
                  "id": "10993984699"
              }
          },
          {
              "campaign": {
                  "resourceName": "customers/5412366484/campaigns/10994168051",
                  "name": "App-1",
                  "id": "10994168051"
              }
          },
          {
              "campaign": {
                  "resourceName": "customers/5412366484/campaigns/21100943970",
                  "name": "Business Book",
                  "id": "21100943970"
              }
          }
      ],
      "fieldMask": "campaign.id,campaign.name",
      "requestId": "uRB8FVooC-dp3aBfcnJM8A",
      "queryResourceConsumption": "102"
  }
]

  const handleGoogleLoginClick = () => {
    const client_id = '125927670553-lmeh23m630mhftc7lviefssjggmtatbp.apps.googleusercontent.com';
    const redirect_uri = "http://localhost:3000/leads-google-gradel";
    const scope = "https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/analytics.readonly";
    const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${encodeURIComponent(scope)}&access_type=offline&prompt=consent`;
    window.location.href = url;
  };

  useEffect(() => {
    const getGoogleLogin = async () =>{
      // let google_data = null;
          // if (!check_user_login(false)) {
          //   console.log('login');
          //   const urlParams = new URLSearchParams(window.location.search);
          //   const code = urlParams.get('code');
          //   if (code) {
          //     check_user_login(true);
          //      google_data = await googleAdsApiService("http://localhost:3000/leads-google-analytics");
          //      update_ui(google_data);
          //   }
              
          // }else{
          //   google_data = await googleAdsApiService("http://localhost:3000/leads-google-analytics");
          //     update_ui(google_data);
          //     console.log('else');
          // }
       update_ui(google_data);
    } 

    getGoogleLogin();
  }, []);
  

  const check_user_login = (save) => { 
    const id = firebase.auth().currentUser.uid;
    if (save) {
      Cookies.set(id, 'true',{ expires: 3650, path: '/' });
      setloginState(true);
    }
    else { 
      if (Cookies.get(id)) {
        setloginState(true);
      }
      else {
        console.log(Cookies.get(id));
      }
    }
  }

   const update_ui= (google_data) =>{
    console.log(google_data);
    if(google_data ||  google_data.access_token){
      if(google_data.access_token){
        setaccessToken(google_data.access_token);
        setloginState(true);
       setAdAccounts(cus_list);
        // fetch_customer_id(google_data.access_token);

      }
    }
   }

   const fetch_customer_id = (access_token) =>{
    fetch('http://localhost:3001/customerids', {
      method: 'POST',
      headers:{
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessToken : access_token
      })
    })
    .then(response =>response.json())
    .then(data => setAdAccounts(data))
    .catch(error => console.error('Error:', error));      
   }
  
   const fetch_camp_names = (customerId) =>{
    const query =` SELECT campaign.id,campaign.name FROM campaign`;
    fetch('http://localhost:3001/fetch-google-ads-camp-data', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          accessToken: access_token, 
          customerId: customerId,
          query : query
      })
  })
  .then(response =>response.json())
  .then(data => setadcamp(data.results.map(camp => camp.campaign)))
  .catch(error => console.error('Error:', error));  
   }


   //Selections Handeleing
   const selectedAdAccount = (value) => {
    setAdAccountValue(value);
    // fetch_camp_names(value);
    console.log(camp_val[0].results);
    setadcamp(camp_val[0].results);
  };

  const selectedCampaignValue = (value) => {
    console.log(value);
  };

  return (
    <div className='full-height'>
      {!loginState ? (
         <Row align='middle' justify='center'>
         <Col span={24} justify='center' align='middle'>
           <Title style={{ marginBottom: 30 }}>Login With Google to Check Your AdScore</Title>
           <div style={{ marginBottom: '80px' }}>
             <button className="google-login-button" onClick={handleGoogleLoginClick}>
               Continue with Google
             </button>
           </div>
         </Col>
       </Row>
      ):(
        <Col span={24} justify='center' align='middle'>
        <Title>Check Your Ad Report in One Click</Title>
        <div style={{ marginBottom: '20px' }}>
          <h2 style={{ color: "#0d6efd", fontSize: "18px", marginBottom: '20px', marginTop: "30px" }}>Select your Ad Account</h2>
          <Select 
            placeholder="Select Ad Account"
            style={{ width: '80%' }}
            onChange={selectedAdAccount}>
            {adAccounts.map(accounts => (
              <Option key={accounts.resourceName.replace('customers/', '')} value={accounts.resourceName.replace('customers/', '')}>{accounts.customerName} ({accounts.resourceName.replace('customers/', '')})</Option>
            ))}
          </Select>
        
          <h2 style={{ color: "#0d6efd", fontSize: "18px", marginTop: "40px" }}>Select your Ad Campaigns</h2>
          <p style={{ fontSize:"12px" }}>This Campaigns from your account which you select Previously</p>

          <Select 
            placeholder="Select Campaign"
            style={{ marginTop: "30px", marginBottom: "20px", width: "80%" }}
            onChange={selectedCampaignValue}>
            {adcamp.map(campaigns => (
              <Option key={campaigns.campaign.id} value={campaigns.campaign.id}>{campaigns.campaign.name}</Option>
            ))}
          </Select>
        </div>
        <button className='custom-facebook-report-button'>Get Report</button>
      </Col>
      )};

    </div>
  );
};

export default GoogleGradel;
