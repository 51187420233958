import { CaretDownFilled, LinkOutlined } from '@ant-design/icons'
import { Button as AntButton, Popover, Row, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Sparklines, SparklinesBars } from 'react-sparklines'
import {
  PAGE_HEAD_TITLE,
  RANKED_KEYWORDS_API_DATA_LIMIT,
} from '../../constants'
import AppContext from '../../context/AppContext'
import { getRankedKeywords } from '../../services/keywordLabsApiService'
import {
  checkQuotaValidation,
  updateRequestUserQuota,
} from '../../services/quotaCheckService'
import BunnyPlayer from '../BunnyPlayer'
import LocationDropdown, { locationOptions } from '../locationDropdown'
import './style.css'

const SpyAds = (props) => {
  const { setLoadingFullScreen } = useContext(AppContext)

  const authUser = props.user
  let keywordApiDataLimit = RANKED_KEYWORDS_API_DATA_LIMIT

  const [inputKey, setInputKey] = useState('')
  const [searchData, setSearchData] = useState([])
  const [activeTab, setActiveTab] = useState(0)

  const [flag, setflag] = useState(false)
  const [flag2, setflag2] = useState(false)
  // const [loading, setLoadingFullScreen] = useState(false);
  const [locationData, setLocationData] = useState(locationOptions[6])

  useEffect(() => {
    document.title = `Search Ads  ${PAGE_HEAD_TITLE}`
  }, []) //eslint-disable-line

  const findKeywords = async (type) => {
    if (!inputKey) {
      notification['error']({ message: 'Please enter search keyword!' })
    } else {
      setLoadingFullScreen(true)

      const checkResponse = await checkQuotaValidation('SPY_ADS', authUser, {
        keywordApiDataLimit,
      })
      // const checkResponse = { valid: true }

      // console.log("checkResponse:", checkResponse)

      if (checkResponse.valid === false) {
        setLoadingFullScreen(false)
        // console.log("NO ACCESS:", checkResponse.message)
      } else {
        let offset = 0
        if (type === 'loadMore') {
          offset = searchData.length
        }
        const dts = {
          target: inputKey,
          location_code: locationData.key,
          currency_code: locationData.currencyCode,
          currency_symbol: locationData.currencySymbol,
          limit: keywordApiDataLimit,
          offset: offset,
        }
        const data = await getRankedKeywords(dts)

        if (data.length) {
          // console.log("data:", data)
          if (type === 'search') {
            setSearchData(data)
            setActiveTab(0)
          }

          if (type === 'loadMore') {
            setSearchData([...searchData, ...data])
          }
        } else {
          console.log('No records found')
          // alert("No records found");
          if (type !== 'loadMore') {
            setSearchData([])
            notification['error']({
              message: 'No results!!',
              placement: 'topRight',
            })
          } else {
            notification['error']({ message: 'No more records found!' })
          }
        }

        setLoadingFullScreen(false)
        // UPDATE USER QUOTA
        updateRequestUserQuota('SPY_ADS', authUser, {})
      }
    }
  }

  const sortDataOnTheBasisOfMonVol = () => {
    if (searchData.length && !flag) {
      setSearchData(searchData.sort((a, b) => a.searchVolume - b.searchVolume))
    } else {
      setSearchData(searchData.reverse())
    }
    setflag(!flag)
  }

  const sortDataOnTheBasisOfCpc = () => {
    if (searchData.length && !flag2) {
      setSearchData(
        searchData.sort((a, b) => a.cpc.substring(2) - b.cpc.substring(2)),
      )
    } else {
      setSearchData(searchData.reverse())
    }
    setflag2(!flag2)
  }

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <BunnyPlayer type="SPY_SEARCH_ADS" />

        <LocationDropdown
          defaultValue={locationData}
          onChange={(value) => {
            setLocationData(value)
          }}
        />

        <Row className="searchfbInterest">
          <div className="search-input-container">
            <input
              type="text"
              className="text-input"
              placeholder="Enter URL"
              name="search"
              value={inputKey}
              onChange={(e) => setInputKey(e.target.value)}
            />

            <button
              type="submit"
              className="primary-button search-button"
              onClick={() => findKeywords('search')}
            >
              Search
            </button>
          </div>
        </Row>

        {searchData.length ? (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio1"
              autoComplete="off"
              checked={activeTab === 0}
              onChange={() => setActiveTab(0)}
            />
            <label className="btn btn-outline-primary" htmlFor="btnradio1">
              Positions
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio2"
              autoComplete="off"
              checked={activeTab === 1}
              onChange={() => setActiveTab(1)}
            />
            <label className="btn btn-outline-primary" htmlFor="btnradio2">
              Ad Copies
            </label>
          </div>
        ) : null}

        <Row className="fb_interest_list_page">
          {searchData.length ? (
            activeTab === 0 ? (
              <div className="fb_hidden_list display-table">
                <div
                  className="table-tr ant-list-header"
                  style={{ backgroundColor: '#175bcb' }}
                >
                  <div className="table-td">Keyword</div>
                  <div className="table-td" style={{ width: '180px' }}>
                    <Popover
                      content={
                        'Represents the average position of the advertisement'
                      }
                      className=""
                    >
                      Position
                    </Popover>
                    {/* represents the average position of the advertisement */}
                  </div>
                  <div className="table-td">
                    CPC
                    <CaretDownFilled onClick={sortDataOnTheBasisOfCpc} />
                  </div>
                  <div className="table-td">
                    Volume
                    <CaretDownFilled onClick={sortDataOnTheBasisOfMonVol} />
                  </div>
                  <div className="table-td">URL</div>
                  <div className="table-td">Traffic</div>
                  <div className="table-td">
                    Trends <br />
                    {'(Latest <- Oldest)'}
                  </div>
                </div>

                {searchData.map((item, index) => {
                  return (
                    <div key={index} className="table-tr">
                      <div className="table-td">{item.keyword}</div>
                      <div className="table-td">{item.position}</div>
                      <div className="table-td">{item.cpc}</div>
                      <div className="table-td">{item.searchVolume}</div>
                      <div className="table-td">
                        <a href={item.url} target="_blank" rel="noreferrer">
                          URL
                        </a>
                      </div>
                      <div className="table-td">{item.traffic}</div>
                      <div className="table-td">
                        <div style={{ width: '100px' }}>
                          <Sparklines
                            width={100}
                            height={25}
                            data={item.monthlySearches}
                          >
                            <SparklinesBars
                              style={{ fill: '#00bdcc', fillOpacity: '.5' }}
                            />
                          </Sparklines>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="row">
                {searchData.map((item, index) => {
                  const serpItem = item.serpItem
                  return (
                    <div key={`url-${index}`} className="col-sm-4 mt-2">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">
                            <a
                              href={serpItem.url}
                              target="_blank"
                              rel="noreferrer"
                              style={{ fontSize: '14px' }}
                            >
                              {serpItem.title}
                              <span
                                style={{
                                  position: 'relative',
                                  left: '10px',
                                  top: '-2px',
                                }}
                              >
                                <LinkOutlined />
                              </span>
                            </a>
                          </h5>
                          <p className="card-text" style={{ fontSize: '14px' }}>
                            {serpItem.description}
                          </p>
                          <a
                            href={`https://${serpItem.domain}`}
                            target="_blank"
                            rel="noreferrer"
                            className=""
                            style={{ fontSize: '14px' }}
                          >
                            {serpItem.domain}
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          ) : null}

          {searchData.length ? (
            <div className="pagination-wrapper">
              <AntButton
                className="mt-10"
                onClick={() => findKeywords('loadMore')}
              >
                Load more results..
              </AntButton>
            </div>
          ) : null}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SpyAds)
