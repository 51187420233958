import axios from 'axios';
import { FIREBASE_URL_AI_GENERATOR } from '../constants';

export const aiGeneratorService = async (params) => {

    let errorData;

    const data = await axios.post(FIREBASE_URL_AI_GENERATOR, params
        // {
        //     apiUrl: 'https://api.openai.com/v1/engines/davinci-instruct-beta-v3/completions',
        //     prompt: prompt,
        //     temperature: temperature,
        //     maxTokens: 1344,
        //     topP: 1,
        //     frequencyPenalty: 2,
        //     presencePenalty: 1,
        //     stop: stop
        // }
        , {
            headers: { 'Accept': 'application/json' }
        })
        .then(response => response.data)
        .catch((error) => {
            // console.log(error);
            errorData = error;
        });

    if (data) {
        return data;
    } else {
        return errorData;
    }
}
