/* eslint-disable */
import moment from 'moment'
import { Link } from 'react-router-dom'

export const dateDisplayFormat = (date) => {
  return date ? moment(date.toDate()).format('DD-MM-YYYY') : '-'
}

export const getUserAccountPlan = (accountData) => {
  let subscriptionPlan =
    accountData && accountData.subscriptionPlan
      ? accountData.subscriptionPlan
      : null

  if (!subscriptionPlan || !subscriptionPlan.planId) {
    return 'FREE'
  }

  if (subscriptionPlan.planId === 'FREE') {
    return 'FREE'
  }

  if (subscriptionPlan.planId === 'ADMIN') {
    return 'ADMIN'
  }

  if (
    subscriptionPlan.paymentStatus &&
    subscriptionPlan.paymentStatus === 'paid'
  ) {
    let accountType = subscriptionPlan.planId

    const expiryDate = moment(subscriptionPlan.expiryDate.toDate())
    const isBefore = moment().isBefore(expiryDate)
    const isSame = moment().isSame(expiryDate)

    if (isBefore === false && isSame === false) {
      return 'EXPIRED'
    } else {
      return accountType
    }
  } else {
    return 'FREE'
  }
}

export const numberToTwoDecimal = (num) => {
  return num ? num.toFixed(2) : '-'
}

export const subscribeToExport = (
  <div>
    {' '}
    <Link to="/plans">Subscribe</Link> to export!
  </div>
)

export const pleaseSubscribe = (
  <div>
    Please <Link to="/plans">subscribe!</Link>
  </div>
)

export const formatNumber = (value) => {
  return isNaN(value) ? '' : value
}

export const humanReadableNumber = (value, na = false) => {
  if (value) {
    let x = value
    x = x.toString()
    let lastThree = x.substring(x.length - 3)
    let otherNumbers = x.substring(0, x.length - 3)
    if (otherNumbers != '') lastThree = ',' + lastThree
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree
    if (res == 0) {
      if (na) {
        return 'N/A'
      } else {
        return res
      }
    } else {
      return res
    }
  } else {
    if (value == 0) {
      if (na) {
        return 'N/A'
      } else {
        return value
      }
    }
  }
}

export const nFormatter = (ComparePrice) => {
  if (ComparePrice < 1e10) {
    if (ComparePrice >= 1000000) {
      return (ComparePrice / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
    if (ComparePrice >= 1e5) {
      return (ComparePrice / 1e5).toFixed(0).replace(/\.$/, '') + 'L'
    }
    if (ComparePrice >= 1000) {
      return (ComparePrice / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }
    return ComparePrice
  }
}

/**
 * Removes accents, replaces special characters with hyphens, also removing additional hyphens *
 * @param {string} str
 * @returns Formateed string
 */
export const replaceSpecialChars = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
    .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
    .replace(/(^-+|-+$)/, '') // Remove extra hyphens from beginning or end of the string
}

export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

export const createTwoArrayFromOne = (array) => {
  const lengthArray = array.length
  const divideKey = lengthArray / 2

  const slice1 = array.slice(0, divideKey)
  const slice2 = array.slice(divideKey, lengthArray)

  return { slice1, slice2 }
}

export const createSlicesFromArray = (array, noOfSlices) => {
  const output = {}
  const lengthArray = array.length
  const divideKey = lengthArray / noOfSlices

  const floor = Math.floor(divideKey)
  const modulo = lengthArray % noOfSlices

  let count = floor
  let j = modulo
  for (let i = 0; i < noOfSlices; i++) {
    let inc = 0
    if (j > 0) {
      inc = 1
      j--
    }
    if (i === 0) {
      count = count + inc
      output[`layer_` + (i + 1)] = array.slice(0, count)
    } else {
      const startIndex = count
      const endIndex = count + floor + inc
      count = endIndex
      output[`layer_` + (i + 1)] = array.slice(startIndex, endIndex)
    }
  }

  return output
}

export const structuredSchemaofKeywords = (response) => {
  if (!response) {
    return []
  }
  const { tasks } = response
  if (!tasks || !tasks[0]) {
    return []
  }
  const { result } = tasks[0]
  if (!result || !result[0]) {
    return []
  }
  const { items } = result[0]
  if (!items) {
    return []
  }

  // console.log("API DATA 1:", items);

  const data = items.map((ele) => ({
    keyword: ele.keyword_data.keyword,
    searchVolume: ele.keyword_data.keyword_info.search_volume,
    cpc: ele.keyword_data.keyword_info.cpc
      ? ele.keyword_data.keyword_info.cpc.toFixed(2)
      : '-',
    // cpc: ele.keyword_data.keyword_info.cpc,
    competition: Math.floor(ele.keyword_data.keyword_info.competition * 100),
  }))
  return data
}
